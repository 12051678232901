import { useEffect, useRef, useState } from "react";

function useExamTimer(durationInMinutes, submitTrigger) {
  const [timeLeft, setTimeLeft] = useState(() => {
    const totalSeconds = durationInMinutes * 60;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return { hours, minutes, seconds };
  });

  const isTriggered = useRef(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        const totalSecondsLeft =
          prevTime.hours * 3600 + prevTime.minutes * 60 + prevTime.seconds - 1;

        if (totalSecondsLeft <= 0) {
          clearInterval(timer);
          if (!isTriggered.current) {
            submitTrigger();
            isTriggered.current = true;
          }
          // submitTrigger();
          return { hours: 0, minutes: 0, seconds: 0 };
        }

        const hours = Math.floor(totalSecondsLeft / 3600);
        const minutes = Math.floor((totalSecondsLeft % 3600) / 60);
        const seconds = totalSecondsLeft % 60;

        return { hours, minutes, seconds };
      });
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, [durationInMinutes, submitTrigger]);

  return timeLeft;
}

export default useExamTimer;
