import React, { Fragment, useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../../services";
import {
  GET_EXAM_TYPES,
  POST_USER_CATEGORY,
} from "../../../services/ApiConstant";
import { message } from "antd";
import { Head } from "../../../Components/seo";

const ExamType = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [contextHolder] = message.useMessage();
  const [examTypes, setExamTypes] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [gotRes, SetGotRes] = useState();

  useEffect(() => {
    getRequest(GET_EXAM_TYPES).then((res) => {
      setExamTypes(res?.data);
    });
  }, []);

  const updateExamType = (id, plans) => {
    setCurrentId(id);
    gotRes === undefined &&
      postRequest(POST_USER_CATEGORY + `?category_id=${id}`)
        .then((res) => {
          SetGotRes(res);
          navigate("/pricing", { state: { id, plans } });
        })
        .catch((e) => {
          setError(e.response.data.error[0]);
        });
  };

  console.log(examTypes);
  // { name, description, icon, id, coming_soon, plans }

  return (
    <Fragment>
      <AuthCard
        link="/"
        examtype={true}
        loginPage={true}
        linkText={""}
        subtitle=""
        page="Choose Exam Type"
        step="02"
        logo
      >
        <Head title="Choose Exam Type" />
        <p className="subtitle text-center">
          Choose Exam Type you wish to register for from the underlisted
          Category
        </p>
        {examTypes &&
          examTypes.map((exam, index) => (
            <div key={`${exam.id}-${index}`}>
              <div
                className={
                  exam.coming_soon ? "exam-card cursor-default" : "exam-card"
                }
                onClick={() => {
                  if (!exam.coming_soon) {
                    console.log("exam plans ~>", exam.plans);
                    updateExamType(exam.id, exam.plans);
                  }
                }}
              >
                <img src={exam.icon} className="icon" />
                <div className="row-between">
                  <div className="pr-20">
                    <div className="title">
                      {exam.name}{" "}
                      {exam.coming_soon && (
                        <p className="error-msg">(Coming soon)</p>
                      )}
                    </div>
                    <div className="desc">{exam.description}..</div>
                  </div>
                  <div
                    className="row-center pr-10"
                    onClick={() => {
                      if (!exam.coming_soon) {
                        console.log("exam plans ~>", exam.plans);
                        updateExamType(exam.id, exam.plans);
                      }
                    }}
                  >
                    <AiOutlineArrowRight size={"24px"} color="#2F327D" />
                  </div>
                </div>
              </div>
              {exam.id == currentId && <div className="error-msg">{error}</div>}
            </div>
          ))}
      </AuthCard>
    </Fragment>
  );
};

export default ExamType;
