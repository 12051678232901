import "antd/dist/reset.css";
import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Signin from "./pages/Auth/Signin";
import CreateAccount from "./pages/Auth/CreateAccount";
import ExamType from "./pages/Auth/ExamType";
import PreferedSubscription from "./pages/Auth/Pricing/pricing";
import ExamBody from "./pages/Auth/ExamBody";
import Payments from "./pages/Auth/Payments";
import Dashboard from "./pages/Dashboard";
import Layout from "./Layout";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import CreatePassword from "./pages/Auth/CreatePassword";
import ProfileSetting from "./pages/ProfileSettings";
import OtherExam from "./pages/OtherExam";
import Contact from "./pages/ContactUs";
import Notification from "./pages/Notification";
import JAMBSimulation from "./pages/JAMBSimulation";
import Layout2 from "./Layout2";
import ExamSimulation from "./pages/Auth/ExamSimulator";
import Instruction from "./pages/JAMBSimulation/instruction";
import ExamQuestion from "./pages/Auth/ExamSimulator/exam-question";
import Result from "./pages/Auth/ExamSimulator/result";
import AmbassodorSignin from "./pages/Auth/Signin/ambassodorSignin";
import AmbassodorContact from "./pages/ContactUs/amb-contactUs";
import AmbassodorSettings from "./pages/ProfileSettings/amb-setting";
import AmbassodorOverview from "./pages/Dashboard/amb-overview";
import AmbassodorPayment from "./pages/Payment/amb-payment";
import AmbassodorLayout from "./amb-Layout";
import ExamAnswer from "./pages/Auth/ExamSimulator/exam-answer";
import AmbassodorCreateAccount from "./pages/Auth/CreateAccount/amb-createAccount";
import AmbassodorBankDetails from "./pages/Auth/ExamType/amb-bankDetails";
import SubcribeExam from "./pages/OtherExam/SubcribeExam";
import JAMBquestion from "./pages/JAMBSimulation/examQuestion";
import JAMBResult from "./pages/JAMBSimulation/jamb_result";
import JAMBAnswer from "./pages/JAMBSimulation/examAnswer";
import OTP from "./pages/Auth/OTP";
import UTMESimulation from "./pages/UTME";
import UTMEQuestion from "./pages/UTME/UTMEQuestion";
import UTMEAnswer from "./pages/UTME/UTMEAnswer";
import UTMEResult from "./pages/UTME/UTMEResult";
import { PrivateRoute, PrivateRoute2 } from "./Components/PrivateRoute";
import IELTSSimulation from "./pages/IELTS";
import IELTSInstruction from "./pages/IELTS/intruction";
import IELTSTASK1 from "./pages/IELTS/ieltsTask1";
import IELTSTASK2 from "./pages/IELTS/ieltsTask2";
// import Error from "./Components/Error";
import SATSimulation from "./pages/SAT";
import SATInstruction from "./pages/SAT/instrucation";
import SATMAthExam from "./pages/SAT/mathExam";
import SATMathQuestion from "./pages/SAT/math_question";
import IELTSANSWER from "./pages/IELTS/examAnswer";
import ReadingAns from "./pages/SAT/readingAns";
import SATMathAnswer from "./pages/SAT/mathExamAns";
import IELTSListeningDemo from "./pages/IELTS/ieltsListeningIntro";
import IELTSListeningTask1 from "./pages/IELTS/ieltsListeningTask1";
import AmbNotification from "./pages/Notification/amb-notification";
import IELTSListeningAns from "./pages/IELTS/ieltsListeningAns";
import { MyContext } from "./assets/common/MyContext";
import LogRocket from "logrocket";
import SearchPage from "./pages/Dashboard/SearchPage";
import { PredictedExam, PredictedQuestions } from "./pages/PredictedQuestions";
import InterCom from "@intercom/messenger-js-sdk";

function App() {
  const [updatedData, setUpdatedData] = useState();
  const [profileImage, setProfileImage] = useState();

  const isLoggedInValue = localStorage.getItem("token");
  const [isLoggedIn, setIsLoggedIn] = useState(!!isLoggedInValue);
  const [userProfileImage, setUserProfileImage] = useState();
  const [bankDetails, setBankDetails] = useState();
  const [searchData, setSearchData] = useState();

  const [searchWord, setSearchWord] = useState();

  LogRocket.init("q2hwze/excelminds");

  const [notificationCount, setNotificationCount] = useState(0);

  function fetchingUpdatingData(data) {
    setUpdatedData(data);
  }

  function fetchingProfileImage(data) {
    setProfileImage(data);
  }

  const fetchingUserImage = (data) => {
    setUserProfileImage(data);
  };

  useEffect(() => {
    if (
      window.location.protocol !== "https:" &&
      window.location.hostname !== "localhost"
    ) {
      const secureUrl = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
      window.location.replace(secureUrl);
    }
  }, []);

  const getBankDetails = (values) => {
    setBankDetails(values);
  };

  const userData = localStorage.getItem("userData");
  console.log(JSON.parse(userData));

  InterCom({
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    user_id: userData?.user?.id,
    name: userData?.full_name,
    email: userData?.user?.email,
    created_at: userData?.user?.date_joined,
  });

  return (
    <MyContext.Provider
      value={{
        notificationCount,
        setNotificationCount,
        searchData,
        setSearchData,

        setSearchWord,
        searchWord,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                replace
                to={`${isLoggedIn ? "dashboard/home" : "Signin"}`}
              />
            }
          />
          {!isLoggedIn ? (
            <Fragment>
              <Route
                path="Signin"
                element={
                  <PrivateRoute2 isLoggedIn={isLoggedIn}>
                    <Signin setIsLoggedIn={setIsLoggedIn} />
                  </PrivateRoute2>
                }
              />
              <Route
                path="AmbassodorSignin"
                element={
                  <PrivateRoute2 isLoggedIn={isLoggedIn}>
                    <AmbassodorSignin />
                  </PrivateRoute2>
                }
              />
              <Route
                path="create-account"
                element={
                  <PrivateRoute2 isLoggedIn={isLoggedIn}>
                    <CreateAccount setIsLoggedIn={setIsLoggedIn} />
                  </PrivateRoute2>
                }
              />

              <Route
                path="amb-create-account"
                element={
                  <PrivateRoute2 isLoggedIn={isLoggedIn}>
                    <AmbassodorCreateAccount />
                  </PrivateRoute2>
                }
              />
              <Route
                path="exam-type"
                element={
                  <PrivateRoute2 isLoggedIn={isLoggedIn}>
                    <ExamType />
                  </PrivateRoute2>
                }
              />
              <Route
                path="pricing"
                element={
                  <PrivateRoute2>
                    <PreferedSubscription />
                  </PrivateRoute2>
                }
              />
              <Route
                path="otp-verify"
                element={
                  <PrivateRoute2 isLoggedIn={isLoggedIn}>
                    <OTP />
                  </PrivateRoute2>
                }
              />
              <Route
                path="amb-bank-details"
                element={
                  <PrivateRoute2 isLoggedIn={isLoggedIn}>
                    <AmbassodorBankDetails
                      getBankDetails={getBankDetails}
                      setIsLoggedIn={setIsLoggedIn}
                    />
                  </PrivateRoute2>
                }
              />
              <Route
                path="exam-body"
                element={
                  <PrivateRoute2 isLoggedIn={isLoggedIn}>
                    <ExamBody />
                  </PrivateRoute2>
                }
              />
              <Route
                path="payment"
                element={
                  <PrivateRoute2 isLoggedIn={isLoggedIn}>
                    <Payments />
                  </PrivateRoute2>
                }
              />
              <Route
                path="forgot-password"
                element={
                  <PrivateRoute2 isLoggedIn={isLoggedIn}>
                    <ForgotPassword />
                  </PrivateRoute2>
                }
              />
              <Route
                path="create-password"
                element={
                  <PrivateRoute2 isLoggedIn={isLoggedIn}>
                    <CreatePassword />
                  </PrivateRoute2>
                }
              />
            </Fragment>
          ) : null}
          <Route
            path="dashboard"
            element={<Navigate replace to="/dashboard/home" />}
          />
          <Route
            path="exam"
            element={<Navigate replace to="/dashboard/home" />}
          />
          <Route
            path="exam"
            element={
              <PrivateRoute isLoggedIn={isLoggedIn}>
                <Layout2 />
              </PrivateRoute>
            }
          >
            <Route
              path="jamb-simulation"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <JAMBSimulation />
                </PrivateRoute>
              }
            />
          </Route>

          <Route path="exam" element={<Layout2 examsimulation={true} />}>
            <Route
              path="predicted-questions"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <PredictedQuestions />
                </PrivateRoute>
              }
            />
            <Route
              path="predicted-questions/exam"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <PredictedExam />
                </PrivateRoute>
              }
            />
            <Route
              path="exam-simulation"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ExamSimulation />
                </PrivateRoute>
              }
            />
            <Route
              path="instruction"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <Instruction />
                </PrivateRoute>
              }
            />
            <Route
              path="exam-question"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ExamQuestion />
                </PrivateRoute>
              }
            />
            <Route
              path="check-corrections"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ExamAnswer />
                </PrivateRoute>
              }
            />
            <Route
              path="result"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <Result />
                </PrivateRoute>
              }
            />
            <Route
              path="jamb-simulation"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <JAMBSimulation />
                </PrivateRoute>
              }
            />
            <Route
              path="jamb-question"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <JAMBquestion />
                </PrivateRoute>
              }
            />
            <Route
              path="jamb-result"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <JAMBResult />
                </PrivateRoute>
              }
            />
            <Route
              path="jamb-check-corrections"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <JAMBAnswer />
                </PrivateRoute>
              }
            />
            <Route
              path="utme-simulation"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <UTMESimulation />
                </PrivateRoute>
              }
            />
            <Route
              path="utme-question"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <UTMEQuestion />
                </PrivateRoute>
              }
            />
            <Route
              path="utme-result"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <UTMEResult />
                </PrivateRoute>
              }
            />
            <Route
              path="utme-check-corrections"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <UTMEAnswer />
                </PrivateRoute>
              }
            />
            <Route
              path="ielts-simulation"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <IELTSSimulation />
                </PrivateRoute>
              }
            />
            <Route
              path="ielts-instruction"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <IELTSInstruction />
                </PrivateRoute>
              }
            />

            <Route
              path="sat-reading-test"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <SATSimulation />
                </PrivateRoute>
              }
            />
            <Route
              path="sat-instruction"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <SATInstruction />
                </PrivateRoute>
              }
            />
            <Route
              path="sat-math-question"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <SATMathQuestion />
                </PrivateRoute>
              }
            />
            <Route
              path="sat-math-test"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <SATMAthExam />
                </PrivateRoute>
              }
            />
            <Route
              path="sat-math-ans"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <SATMathAnswer />
                </PrivateRoute>
              }
            />
            <Route
              path="sat-reading-ans"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ReadingAns />
                </PrivateRoute>
              }
            />
          </Route>

          <Route path="exam">
            <Route
              path="ielts-task1"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <IELTSTASK1 />
                </PrivateRoute>
              }
            />
            <Route
              path="ielts-task2"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <IELTSTASK2 />
                </PrivateRoute>
              }
            />

            <Route
              path="ielts-listening-demo"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <IELTSListeningDemo />
                </PrivateRoute>
              }
            />
            <Route
              path="ielts-listening-task1"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <IELTSListeningTask1 />
                </PrivateRoute>
              }
            />
            <Route
              path="ielts-listening-ans"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <IELTSListeningAns />
                </PrivateRoute>
              }
            />

            <Route
              path="ielts-check-corrections"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <IELTSANSWER />
                </PrivateRoute>
              }
            />
          </Route>
          {/* <Route path="dashboard" element={<Layout updatedData={updatedData} />}> */}
          <Route
            path="dashboard"
            element={
              <Layout
                updatedData={updatedData}
                setIsLoggedIn={setIsLoggedIn}
                userProfileImage={userProfileImage}
              />
            }
          >
            <Route
              path="home"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="profile-setting"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ProfileSetting
                    fetchingUpdatingData={fetchingUpdatingData}
                    fetchingUserImage={fetchingUserImage}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="other-exams"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <OtherExam />
                </PrivateRoute>
              }
            />
            <Route
              path="predicted-questions"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <PredictedQuestions />
                </PrivateRoute>
              }
            />

            <Route
              path="subcribe-exams"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <SubcribeExam />
                </PrivateRoute>
              }
            />

            <Route
              path="search"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <SearchPage />
                </PrivateRoute>
              }
            />

            <Route
              path="contact"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <Contact />
                </PrivateRoute>
              }
            />
            <Route
              path="notification"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <Notification />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="*" element={<Navigate to={"/"} replace />} />

          <Route
            path="ambassodor"
            element={<Navigate replace to="/ambassodor/ambassodor-overview" />}
          />

          <Route
            path="ambassodor"
            element={
              <PrivateRoute isLoggedIn={isLoggedIn}>
                <AmbassodorLayout
                  setIsLoggedIn={setIsLoggedIn}
                  profileImage={profileImage}
                />
              </PrivateRoute>
            }
          >
            <Route
              path="ambassodor-overview"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <AmbassodorOverview />
                </PrivateRoute>
              }
            />
            <Route
              path="ambassodor-setting"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <AmbassodorSettings
                    fetchingProfileImage={fetchingProfileImage}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="ambassodor-payment"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <AmbassodorPayment bankDetails={bankDetails} />
                </PrivateRoute>
              }
            />
            <Route
              path="ambassodor-notification"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <AmbNotification />
                </PrivateRoute>
              }
            />
            <Route
              path="ambassodor-contact"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <AmbassodorContact />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </MyContext.Provider>
  );
}

export default App;
