import { Modal } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Options from "../../assets/common/Option";
import Question from "../../assets/common/Question";
import useExamTimer from "../../hooks/useExamTimer";
import { getUserData, postRequest } from "../../services";
import { GET_ML_EXAM, SUBMIT_ML_EXAM } from "../../services/ApiConstant";

export const PredictedExam = () => {
  const [examQuestions, setExamQuestions] = useState([]);
  const [examId, setExamId] = useState();
  const [answers, setAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [answerAllQuestionsModal, setAnswerAllQuestionsModal] = useState(false);
  const [submissionConfirmationModal, setSubmissionConfirmationModal] =
    useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [resultData, setResultData] = useState();

  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getUserData(`${GET_ML_EXAM}?exam=${state?.exam}&subject=${state?.subject}`)
      .then((res) => {
        console.log("response ~>", res.data);
        setExamId(res.data[0].id);
        setExamQuestions(res.data[0].mlquestions);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onSelectAnswers = (answer) => {
    setAnswers((prev) => [...prev, answer]);
  };

  const submitAnswers = () => {
    if (examQuestions.length === 0) {
      navigate("..");
    } else {
      if (answers.length !== examQuestions.length) {
        //show modal to tell user to answer all questions
        setAnswerAllQuestionsModal(true);
      } else {
        // show confirmation modal to submit answers
        setSubmissionConfirmationModal(true);
      }
    }
  };
  const submit = () => {
    setSubmissionConfirmationModal(false);
    const predictedAnswerData = {
      mlexam: examId,
      answers,
    };
    // submit answer api
    console.log("submitting ...", predictedAnswerData);
    postRequest(SUBMIT_ML_EXAM, predictedAnswerData).then((res) => {
      setResultModal(true);
      console.log("respose ~>", res.data);
      setResultData(res.data);
    });
  };
  const durationInMinutes = 60;
  const { hours, minutes, seconds } = useExamTimer(durationInMinutes, submit);
  const goHome = () => navigate("/");

  return (
    <Fragment>
      <Modal
        width={316}
        open={answerAllQuestionsModal}
        onClose={() => setAnswerAllQuestionsModal(false)}
        onCancel={() => setAnswerAllQuestionsModal(false)}
        centered
        onOk={() => setAnswerAllQuestionsModal(false)}
      >
        <div className="subjectModal">
          <p className="pb-3">Please, answer all questions.</p>
        </div>
      </Modal>
      <Modal
        width={316}
        open={submissionConfirmationModal}
        onClose={goHome}
        onCancel={goHome}
        centered
        onOk={submit}
      >
        <div className="subjectModal">
          <p className="pb-3">Are you sure you want to submit?</p>
        </div>
      </Modal>
      <Modal
        width={316}
        open={resultModal}
        onClose={() => setResultModal(false)}
        onCancel={() => setResultModal(false)}
        centered
        onOk={goHome}
      >
        <div className="subjectModal">
          <h5>Exam Result</h5>
          <p className="pb-3">
            Well done! You got {resultData?.score} /{" "}
            {resultData?.total_questions}
          </p>
        </div>
      </Modal>

      <div className="predicted_exam">
        <p className="predicted_exam__info">
          These are predicted question, not 100% correct
        </p>
        <div className="predicted_exam__header">
          <h1>Predicted Exam</h1>
          {examQuestions.length > 0 ? (
            <p>
              Time Remaining: {hours > 9 ? hours : `0${hours}`}:
              {minutes > 9 ? minutes : `0${minutes}`}:
              {seconds > 9 ? seconds : `0${seconds}`}
            </p>
          ) : null}
        </div>

        <div className="predicted_exam__questions">
          {isLoading ? (
            <p>Fecthing your exam...</p>
          ) : examQuestions.length === 0 ? (
            <p>Sorry, there are no questions for {state.subject} exam.</p>
          ) : (
            examQuestions.map((question, index) => (
              <div className="qutext" key={question.id}>
                <Question question={question.question_text} no={index + 1} />
                <div className="quoplist">
                  <ul className="cusradiolist">
                    {question.mloptions.map((option) => (
                      <li key={option.id}>
                        <input
                          type="radio"
                          name={question.question_text}
                          id={option.id}
                          value={option.id}
                          onChange={(e) =>
                            onSelectAnswers({
                              mlquestion: question.id,
                              selected_option: Number(e.target.value),
                            })
                          }
                        />
                        <label htmlFor={option.id}>
                          <Options option={option.tag} />
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="qucomm-btn-wrap">
          <button
            className="btn"
            onClick={submitAnswers}
          // disabled={questions === undefined}
          // onClick={() => showModal()}
          >
            {examQuestions.length === 0 ? "Go Back" : "Submit"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};
