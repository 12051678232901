import React from "react";
import { Tabs } from "antd";
import GeneralSettings from "./GeneralSettings";
import ChangePassword from "./ChangePassword";
import PaymentMethod from "./PaymentDetails";
import { Head } from "../../Components/seo";

const ProfileSetting = ({
  step,
  page,
  fetchingUpdatingData,
  fetchingUserImage,
}) => {
  const onChange = () => { };

  const items = [
    {
      key: "1",
      label: `General Settings`,
      children: (
        <GeneralSettings
          fetchingUpdatingData={fetchingUpdatingData}
          fetchingUserImage={fetchingUserImage}
        />
      ),
    },
    {
      key: "2",
      label: `Password Settings`,
      children: <ChangePassword />,
    },
    {
      key: "3",
      label: `Payment Method`,
      children: <PaymentMethod />,
    },
  ];

  return (
    <div className="std-body-without-right-panel">
      <Head title="Profile" />
      <Tabs
        size="large"
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

export default ProfileSetting;
