import { Button, Form, Input, Radio, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { Card } from "antd";
import { getAuthRequest, postRequest } from "../../services";
import { POST_CONSTACT_US, PROFILE_API } from "../../services/ApiConstant";
import { Head } from "../../Components/seo";

const Contact = () => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [messageApi, contextHolder] = message.useMessage();
  const [fullData, setFullData] = useState();
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };

  const onSubmit = (e) => {
    e.type = 1;
    postRequest(POST_CONSTACT_US, e).then(() => {
      info();
      form.resetFields();
    });
  };

  const info = () => {
    messageApi.open({
      type: "success",
      content: "Thank you for contacting us",
    });
  };

  const getData = () => {
    getAuthRequest(PROFILE_API, {}).then((res) => {
      setFullData(res.data);
    });
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (fullData) {
      form.setFieldsValue({
        name: fullData?.full_name,
        email: fullData?.user?.email,
      });
    }
  }, [fullData, form]);

  return (
    <div className="contactUs-sec">
      <Head title="Contact Us" />
      <div className="contactUs-wrapper">
        <div className="left-div">
          {contextHolder}
          <Card>
            <Typography.Title level={3}>How Can We Help?</Typography.Title>
            <Typography.Title level={5}>
              Let us know your thought
            </Typography.Title>

            <Form
              layout="vertical"
              form={form}
              initialValues={{
                layout: formLayout,
                name: fullData?.full_name,
                email: fullData?.user?.email,
              }}
              onValuesChange={onFormLayoutChange}
              onFinish={(e) => onSubmit(e)}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Name",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Full Name" disabled />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input placeholder="Your Email" disabled />
              </Form.Item>

              <Form.Item
                label="Message"
                name="message"
                className="for-textarea"
                rules={[
                  {
                    required: true,
                    message: "Please input your message",
                    whitespace: true,
                  },
                ]}
                labelAlign="top"
              >
                <Input.TextArea
                  placeholder="Write Your Message"
                  style={{ height: 120, marginBottom: 24 }}
                />
              </Form.Item>

              <div className="form-actions">
                <Button type="primary" block onClick={() => form.submit()}>
                  Send
                </Button>
              </div>
            </Form>
          </Card>
        </div>
        <div className="right-div">
          <img src="/images/Telephone.png" alt="telephone" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
