import { Col, Divider, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import {
  getAuthRequest,
  getRequest,
  getUserData,
  postRequest,
} from "../../services";
import {
  GET_NOTIFICATION_DETAILS,
  GET_NOTIFICATION_READ_DETAILS,
} from "../../services/ApiConstant";
import { MyContext } from "../../assets/common/MyContext";
import { useNavigate } from "react-router-dom";
import { Head } from "../../Components/seo";

const Notification = () => {
  const [notification, setNotification] = useState([]);
  const [showNotification, setShowNotification] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const { notificationCount, setNotificationCount } = useContext(MyContext);
  const naviagte = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      getUserData(GET_NOTIFICATION_DETAILS).then((res) => {
        setNotification(res?.data);
        let count = 0;
        res?.data.map((i) => {
          if (!i.read_status) {
            count = count + 1;
          }
        });
        setNotificationCount(count);
      });
    }, 100);
  }, [showNotification]);

  const readNotification = (item) => {
    setShowNotification(item);
    setShowMore(true);
    postRequest(
      GET_NOTIFICATION_READ_DETAILS + `?notification_id=${item.id}`,
    ).then((res) => { });
  };

  const GetDate = (date) => {
    const gotDate = new Date(date);
    const convertedDate = new Date(gotDate);
    return (
      date !== undefined && (
        <div>
          <div className="notification-date">
            <span>Date : </span>
            <span style={{ marginLeft: "2px" }}>
              {" "}
              {convertedDate.toLocaleDateString()}
              <br />
            </span>
          </div>
          <div className="notification-time">
            <span>Time : </span>
            <span style={{ marginLeft: "2px" }}>
              {convertedDate.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </span>
          </div>
        </div>
      )
    );
  };

  const Capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  return (
    <>
      <Head title="Notification" />
      {notification.length > 0 ? (
        <Row style={{ margin: 30 }}>
          <Col xs={24} sm={24} md={24} lg={8}>
            <div className="notificationboxwrap">
              {notification.map((item) => {
                return (
                  <div
                    className={
                      !item?.read_status
                        ? "notification-box"
                        : "notification-box afterRead"
                    }
                    onClick={() => {
                      readNotification(item);
                    }}
                  >
                    <div className="info" style={{ cursor: "pointer" }}>
                      <h4>
                        {item?.title.length > 30 && !showMore
                          ? Capitalize(item.title.substring(0, 30)) + "..."
                          : Capitalize(item.title)}
                      </h4>
                      <p>{item?.details.substring(0, 100)}</p>
                    </div>
                    <div className="unreadbox">
                      {!item?.read_status && <p>Unread</p>}
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          {/* <Divider type="vertical"></Divider> */}
          {
            <Col span={16}>
              <div className="notificationtext">
                <h3>
                  {Capitalize(showNotification?.title)
                    ? Capitalize(showNotification?.title)
                    : ""}
                </h3>
                {showNotification != undefined &&
                  GetDate(showNotification.updated_time)}
                <p>{showNotification.details}</p>
              </div>
            </Col>
          }
        </Row>
      ) : (
        <div className="no-data-show-msg-sec">
          <div className="icon-container">
            <img src="/images/no-data-show.svg" alt=""></img>
          </div>
          <div className="text-container">No data to show</div>
          <div className="action-container for-responsive">
            <button
              type="button"
              className="back-btn"
              onClick={() => naviagte("/dashboard/home")}
            >
              <img src="/images/back-arow-white.svg" alt=""></img>
              Go To Home
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
